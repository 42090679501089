export class Institute {
    alternateEmailId: any = '';
    alternatePhone: any = '';
    createddatetime: any = '';
    emailId: any = '';
    gstno: any = '';
    instituteId: any = '0';
    instituteName: string = '';
    instituteType: any = '';
    shortTerm='';
    lastupdatedatetime: any = '';
    logourl = null;
    phone: any = '';
    serviceUnder: any = 'ServiceCall';
    state: any = '';
    street1: any = '';
    street2: any = '';
    city: any = '';    
    country: any = 'INDIA';
    zipcode: any = '';

    remarks: any;
    keyInfo: any;
    agents: any;

}