<br>

<div class="container" *ngIf="showReset == 0">
    <div class="row justify-content-center">
        <div class="col-5 col-lg-5 col-md-8 col-sm-12 col-xs-12 border border-light p-5">
            <p class="indigo-text h4 mb-4 text-center">Forget Password</p>
            <p>
                Forgotten your password? Enter your email address below to begin the reset process.

            </p>
            <label class="text-left" for="email_id"><b>Email Id</b></label>
            <input type="text" id="email_id" class="form-control" [(ngModel)]="email_id">
            <br>
            <div class="row justify-content-center">
                <div class="col-4">
                    <p class="h4 mb-4 text-center">
                        <button [disabled]="email_id == ''" (click)="forgetPassword()"
                            class="btn btn-md btn-primary text-center">
                            Submit</button>
                    </p>
                   
                </div>
            </div>
        </div>

    </div>
</div>
<div class="container">
    <div class="row justify-content-center" *ngIf="showReset == 1">
        <div class="col-5 col-lg-5 col-md-8 col-sm-12 col-xs-12 border border-light p-5">
            <p class="indigo-text h4 mb-4 text-center">OTP VERIFICATION</p>

            <label class="text-left" for="email_id">Email Id</label>
            <input type="text" id="email_id" class="form-control" [(ngModel)]="email_id" name="email_id" readonly>

            <label class="text-left" for="otp">One Time Password</label>
            <input type="text" id="otp" class="form-control" [(ngModel)]="otp" name="otp">

            <br>
            <div class="row justify-content-center">
                <div class="col-4">
                    <p class="h4 mb-4 text-center">
                        <button [disabled]="otp == ''" (click)="checkOTPCode()"
                            class="btn btn-md btn-primary text-center">
                            Submit</button>
                    </p>
                </div>

            </div>
        </div>
    </div>
</div>
<br>

<div class="container" *ngIf="showReset ==2">
    <div class="row justify-content-center">
        <div class="col-5 col-lg-5 col-md-8 col-sm-12 col-xs-12 border border-light p-5">
            <p class="indigo-text h4 mb-4 text-center">Reset Password</p>

            <label class="text-left" for="new_password">New Password</label>
            <input type="text" id="new_password" class="form-control" [(ngModel)]="new_password">

            <label class="text-left" for="confirm_password">Confirm Password</label>
            <input type="confirm_password" id="confirm_password" class="form-control" [(ngModel)]="confirm_password">
            <br>
            <div class="row justify-content-center">
                <div class="col-4">
                    <p class="h4 mb-4 text-center">
                        <button [disabled]="new_password == '' || confirm_password == '' || submitted"
                            (click)="updatePassword()" class="btn btn-md btn-primary text-center">
                            Update</button>
                    </p>
                </div>

            </div>
        </div>

    </div>
</div>
<br>
<div class="container d-flex justify-content-center p-5" *ngIf="loading">
    <div class="spinner-border text-primary" style="width: 3rem; height: 3rem;" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>